
import { defineComponent, reactive, ref } from 'vue';
import { thirdPartyDeviceApi } from '@/api';
import { GetThirdPartyDeviceForSingleList } from '@/api/distributor/all.type';
import removeFunc from '@/methods/remove-func';
import { ListActionItem } from '@/components/common/list';
import List from '@/components/common/list/src/list.vue';
import { overlayFormData } from '@/util/system';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'project'
        },
        deviceType: {
            type: String,
            default: 'camera'
        }
    },
    components: {
        List
    },
    setup(props) {
        // searchKey选择项
        const searchKeyList = [{
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'Owner',
            label: WordList.TabelPersonDeviceInHtmlOwner
        }, {
            name: 'Installer',
            label: WordList.TabelUserInHtmlRoleInstall
        }];

        const updateData = ref(0);

        // 搜索条件
        const formData = reactive({
            searchKey: 'Location',
            searchValue: '',
            row: 10,
            page: 1,
            type: props.type,
            deviceType: props.deviceType
        });
        overlayFormData(formData);

        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = [
            {
                name: 'Location',
                label: WordList.TabelPersonDeviceInHtmlLocation
            }, {
                name: 'Owner',
                label: WordList.TabelPersonDeviceInHtmlOwner
            }, {
                name: 'Installer',
                label: WordList.TabelUserInHtmlRoleInstall
            }, {
                name: 'CreateTime',
                label: WordList.CreateTime
            }
        ];

        // 表格数据获取
        const tableData = reactive<GetThirdPartyDeviceForSingleList>({
            row: [],
            detail: [],
            total: 0
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            thirdPartyDeviceApi.getThirdPartyDeviceForSingleList({ ...formData }, (res: GetThirdPartyDeviceForSingleList) => {
                tableData.row = res.row;
                tableData.detail = res.detail;
                tableData.total = res.total;
                updateData.value += 1;
            });
        };
        getThirdPartyDeviceApi();

        // 删除
        const deleteThirdPartyDeviceApi = (data: { UUID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                thirdPartyDeviceApi.deleteSingleThirdPartCamera({ UUID: data.UUID }, getThirdPartyDeviceApi);
            });
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [{
            type: 'delete2',
            event(data: { UUID: string }) {
                deleteThirdPartyDeviceApi(data);
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        return {
            formData,
            searchKeyList,
            column,
            getThirdPartyDeviceApi,
            tableData,
            deleteThirdPartyDeviceApi,
            action,
            updateData
        };
    }
});
